.AlertboxPaper {
  border-radius: 24px !important;
  margin-top: 66px !important;
  border: 1px solid #F06601;
}

.ScrollPaper {
  align-items: unset !important;
}
.Alertbox {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
  max-height: 550px;
  height: unset !important;
}

.AlertActions  {
  padding: 16px !important;
  border-top: 1px solid #F06601;
}

.AlertTitle {
  font-size: 24px !important;
  border-bottom: 1px solid #F06601;
  padding: 16px !important;
}

.AlertBody {
  padding: 16px !important;
}

