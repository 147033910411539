/* .Header {
  width: 93%;
  padding-left: 5%;
  padding-top: 1%;
} */

.root {
  display: flex;
  border-radius: 0px !important;
}

/* .details {
  display: flex;
}
.content {
  color: #9288f2;
  display: flex;
  padding: 5px !important;
} */

.leftContent {
  display: inline-flex;
  align-items: center;
  font-size: 16px !important;
}

.rightContent {
  display: inline-flex;
  align-items: right;
}

.siteInfo {
  justify-content: flex-start;
  display: inline-flex;
  align-items: center;
  width: 220px;
  border-right: 1px solid #c1c1c1;
  flex-basis: unset !important;
  padding: 11px 5px;
  height: 50px;
}

.userInfo {
  justify-content: flex-end;
  display: inline-flex;
  align-items: center;
  min-width: calc(100% - 220px);
  padding: 5px 25px;
  background-color: #f06601;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
  color: #fff;
  height:46px;
}

.userNameLabel {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 700;
  font-family: 'Roboto', sans-serif !important;
}

.userIcon {
  margin-left: 10px;
}

.logout {
  cursor:pointer
}

.RALogoImg {
	display: inline-block;
	width: 246px;
	background-color: #fff !important;
	padding: 5px 15px;
	text-align: center;
	font-size: 140%;
	color: #002F60 !important;
	box-shadow: 10px 0 7px -10px rgba(0, 0, 0, 0.45);
	vertical-align: top;
	height: 46px;
	z-index: 100;
	position: absolute;
	top: 0px;
	line-height: 22px;
  cursor: pointer;
}

.RALogoImg img {
  width: 130px;
  height: auto;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.MainOfficeSampleReportDialogboxPaper {
  height: auto !important;
  border: 1px solid #f06601 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
  width:35rem;
  max-height: 80vh !important;
  /* max-width: 60vw !important; */
}

.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #f06601 !important;
  padding: 5px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}

.alert-url{
  background: none; 
  border: none; 
  padding: 0;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 700;
  font-family: 'Roboto', sans-serif !important;
}

@media (max-width: 599.95px) {
  .userInfo {
    height: 46px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }
.RALogoImg {    
    width:72px;
    box-shadow:none;
}
}