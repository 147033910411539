.fallbackContainer {
    height: 75vh;
  }
  
  .fallbackGrid {
    height: 100%;
  }
  
  .fallbackImage {
    width: 250px; 
    margin-bottom: 20px;
  }
  
  .fallbackButton {
    margin-top: 20px;
    background: gray;
  }

  .fallbackHeading{
    margin-bottom: 15px !important;
    padding-bottom: 0px !important;
    margin-left: 7px !important;
    margin-top: 15px !important;
    color: #424242 !important;
  }

  .fallbackSubHeading{
    color: #707070 !important;
  }

  .mailLinkSpan a{
    color: #f06601;
  }