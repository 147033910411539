.backToListButtonPanel {
    text-align: right;
    padding-bottom: 15px;
}
.backToListButton button {
    /* background-color: transparent !important; */
    border: 0px !important;
    box-shadow: none !important;
    font-size: 17px !important;
    font-weight: bolder !important;
    color: #e1e1e1  !important;
    outline: 0 !important;
    text-transform: unset !important;
    padding-right: 14px !important;
    margin-bottom: 3px;
  }
  
  /* .backToListButton button:hover {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    text-transform: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none;
  } */
  
  .CreditMainOfficeDialogboxPaper .DialogBoxTitle {
    display: none;
  }
  .CreditMainOfficeDialogboxPaper .DialogBoxContext {
    height: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .CreditMainOfficeDialogboxPaper, .PricingMainOfficeDialogboxPaper{
    border-radius: 24px !important;
    margin-top: 66px !important;
    border-bottom: 1px solid #f06601 !important;
    width: auto !important;
    max-width: 800px !important;
  }
  .CreditMainOfficeDialogboxPaper {
    height: 185px !important;
  }
  .DialogBoxTitle {
    text-align: center;
    border-bottom: 1px solid #f06601;
    padding: 16px 24px;
  }
  .DialogBoxTitle h1 {
    margin-bottom: 0px !important;
  }
  .DialogBoxContext {
    /* padding: 16px !important; */
    padding: 40px !important;
  }
  .DialogBoxContext p {
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #707070;
    margin: 0px;
  }
  .backToListButton button span img {
    width: 14px !important;
    margin-right: 3px;
  }
  .disfield input {
    background-color: #e1e1e1;
  }
  .borderLess{
    font-family: Roboto,sans-serif;
    color: #F06601 !important;
    padding: 4px 14px !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .AlertWrap >div >div >div > svg{
    width: 2em;
    height: 1.5em;
    font-size:1.2rem;
  }
  
  .AlertWrap >div >div >div + div{
    line-height: 1.5 !important;
    font-size: 16px;
    font-weight: 500 !important;
    padding: 0px;
    align-items: center;
    display: inline-flex;
  }
  
  .AlertWrap >div >div >div + div + div > button span svg{
    width: 2em;
    height: 1.5em;
    font-size:1.2rem;
  }
  .AlertWrap > div > div > div {
    padding: 0px;
    align-items: center;
  }

  .crossBtn button{
    min-width: 0px !important;
  }
  
  .crossBtn .cancleTooltip {
    visibility: hidden;
    width: 60px;
    color: #F06601 !important;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    font-size: 11px;
    left: -10px;
    top: -20px;
  }
  
  .crossBtn:hover .cancleTooltip {
    visibility: visible;
  }

  .alert-url{
    text-decoration: underline;
    cursor: pointer;
    color:red !important;
    background: none; 
    border: none; 
    padding: 0;
    margin-left: 5px; 
    font-weight: 50 !important;
    font-size: medium; 
  }
  .alert{
    color: red !important;
    font-weight: 750
  }