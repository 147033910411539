.backToListButton button {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    text-transform: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none;
  }
  
  .backToListButton button:hover {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    text-transform: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none;
  }
  
  .backToListButton button span img {
    width: 14px !important;
    margin-right: 3px;
  }
  
  .backToListButtonPanel {
    text-align: right;
  }

  .ActionButtons button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .Input {
    display: flex;
    padding: 8px;
  }
  
  .InputBorder {
    border-radius: 12px !important;
    border-width: 2px !important;
  }
  
  .Label {
    font-size: 16px;
    font-weight: 500;
    color: #707070 !important;
  }
  
  .HelperText {
    margin-left: 16px !important;
  }
  
  /*Change border for textfield*/
  
  .ReactInput:focus, .ReactInput:hover fieldset, .ReactInput input+fieldset {
    border-color: #f06601  !important;
    border-width: 1px !important;
    border-radius: 10px !important;
  }
  
  .ReactInput input {
    padding: 5px 7px !important;
    border-radius: 10px !important;
    line-height: 1.846 !important;
    height: 37px !important;
    color: #707070 !important;
    font-size: 14px;
    background-color: #fff;
  }
  
  .ReactInput {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #FFF;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
    border-radius: 20px !important;
    border: 1px solid #f06601;
    position:relative;
  }


  .ScrollTable>div {
    max-height: calc(100vh - 351px) !important;
    overflow-x: auto !important;
  }
  .ScrollTable svg{
    fill:#e53935 !important;
  }
  .disfield input {
    background-color: #e1e1e1;
  }
  .backToListButton button.ActionButton, .backToListButton button.ActionButton:hover, .backToListButton button.ActionButton:focus, .backToListButton button.ActionButton:active{
    padding: 4px 14px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    border: 1px solid #f06601 !important;
    background-color: #f06601  !important;
    color: #fff !important;
    margin-left: 10px;
  }
  .backToListButton button {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    outline: 0 !important;
    text-transform: unset !important;
    padding-right: 0px !important;
    margin-bottom: 3px;
  }
  
  .backToListButton button:hover {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    text-transform: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none;
  }
  
  .backToListButton button span img {
    width: 14px !important;
    margin-right: 3px;
  }
  
  .backToListButtonPanel {
    text-align: right;
    padding-bottom: 15px;
  }
  .borderLess{
    font-family: Roboto,sans-serif;
    color: #F06601 !important;
    padding: 4px 14px !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .DialogBoxTitle {
    text-align: center;
    border-bottom: 1px solid #f06601;
    padding: 16px 24px;
  }
  .DialogBoxTitle h1 {
    margin-bottom: 0px !important;
  }
  .DialogBoxContext {
    padding: 16px !important;
  }
  .DialogBoxContext p {
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #707070;
    margin: 0px;
  }
  .MainOfficeDialogboxPaper{
    height: 200px !important;
    border-radius: 24px !important;
    margin-top: 66px !important;
    border-bottom: 1px solid #f06601 !important;
    width: auto !important;
    max-width: 800px !important;
  }
  .MainOfficeScrollPaper {
    align-items: center !important;
  }
  .CircleIcon2 {
    position: relative;
    bottom: 59px;
    left: 272px;
    height: 20px;

  }
  .timeZoneWrap > div:first-child {
    margin-right: 15px;
  }

  @media (max-width: 1500px) {
    .ReactInput {
      margin-bottom: 10px !important;
      margin-top: 0px !important;
    }
  }