.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #FFF;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  border-radius: 20px !important;
  border: 1px solid #f06601;
  position: relative;
}

.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}

.FilterInput {
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.FilterSearchButton {
  margin-right: 0px;
  margin-bottom: 15px;
}

.FilterResetButton {
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
}

.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}

.FilterResetButton button span {
  font-weight: 600 !important;
}

.InputField {
  border-color: #f06601  !important;
  border-radius: 12px !important;
  border-width: 1px !important;
  width: 320px;
  height: auto;
  font-size: 14px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ButtonGroup1 {
  display: inline-block !important;
}

.ButtonGroup2 {
  display: inline-block !important;
}

th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  padding-left: 20px;
}

th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  padding-right: 20px;
}

th {
  border-bottom: none !important;
}

table>thead {
  background-color: transparent !important;
}

.FormHelperText {
  color: #e53935 !important;
}

.ScrollTable>div {
  max-height: 250calc(100vh - 345px) !important;
  overflow-x: auto !important;
}
.ScrollTable svg{
  fill:#e53935 !important;
}
.ConfirmPasswordWrap label+div{
  width:100%;
  background-color: #fff;
}
.ConfirmPasswordWrap label+div, .ConfirmPasswordWrap > div, .ConfirmPasswordWrap fieldset{
  width:100%;
}
.ConfirmPasswordWrap input {
  padding: 5px 7px !important;
  border-radius: 10px !important;
  line-height: 1.846 !important;
  height: 37px !important;
  color: #707070 !important;
  font-size: 14px;
  background-color: #fff;
}
.ConfirmPasswordWrap label {
  transform: translate(14px, 16px) scale(1);
  color: #707070 !important;
  font-size: 14px;
}
tfoot  {
  display: table-footer-group !important;
}
tfoot tr td > div{
  justify-content: flex-end;
}
tfoot button{
  color: #e53935 !important;
}
tfoot svg {
  fill:#e53935 !important;
}