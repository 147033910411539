.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #FFF;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  border-radius: 20px !important;
  border: 1px solid #f06601;
  position:relative;
}
.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}
.FilterInput {
  width:250px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.FilterSearchButton{
  margin-right: 10px;
  margin-bottom: 10px;
}
.FilterResetButton{
  margin-bottom: 10px;
}
.FilterAddWrap {
  float: right;
  margin-top: 20px;
}
.FilterResetButton button{
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}
.FilterResetButton button span{
  font-weight:600 !important;
}
.HeaderLabel{
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 5px;
}
.ConfigItem label span{
  font-size: 12px;
  color: #707070;
  font-weight: 500;
}
/* .ConfigItem label{
  display: flex;
  margin-bottom: 5px;
  margin-left:0px !important;
} */

.backToListButton button {
  margin-right: 10px;
}

.disfield input {
  background-color: #e1e1e1;
}
.borderLess{
  font-family: Roboto,sans-serif;
  color: #F06601 !important;
  padding: 4px 14px !important;
  line-height: 1.846 !important;
  border-radius: 20px !important;
  font-weight: 500!important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

/* .mainCheck {
  display: flex;
} */

.mainCheck label {
  margin-left: 0px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding:0px !important;
  margin-bottom: 10px !important;
}

.mainCheck label span {
  font-size: 12px !important;
}

.ConfigItem {
  column-count: 5;
    column-gap: 25px;
    vertical-align: top;
}
.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #f06601;
  padding: 16px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
}
.DialogBoxContext {
  padding: 16px !important;
}
.DialogBoxContext p {
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  margin: 0px;
}
.CreditMainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.CreditMainOfficeDialogboxPaper .DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CreditMainOfficeDialogboxPaper, .PricingMainOfficeDialogboxPaper{
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #f06601 !important;
  width: auto !important;
  max-width: 800px !important;
}
.CreditMainOfficeDialogboxPaper {
  height: 185px !important;
}
.PricingMainOfficeDialogboxPaper {
  height: 345px !important;
  width: 420px !important;
}
.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(2),
.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(3) {
  text-align: center;
}