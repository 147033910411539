.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #FFF;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
    border-radius: 20px !important;
    border: 1px solid #f06601;
    position:relative;
  }
  .FilterWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    align-items: flex-end;
  }
  .FilterInput {
    width:250px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .FilterSearchButton{
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .FilterResetButton{
    margin-bottom: 10px;
  }
  .FilterAddWrap {
    float: right;
    margin-top: 20px;
  }
  .FilterResetButton button{
    background-color: transparent !important;
    border: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    box-shadow: none !important;
  }
  .FilterResetButton button span{
    font-weight:600 !important;
  }
  .ActionButtons button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .PasswordField input {
    padding: 5px 7px !important;
    border-radius: 10px !important;
    line-height: 1.846 !important;
    height: 37px !important;
    color: #707070 !important;
    font-size: 14px;
    background-color: #fff;
  }
  
  .PasswordField  {
    color: #707070 !important;
    font-size: 14px;
  }
  .PasswordField> div{
    width:100%;
  }
  
  .PasswordField> div:focus, .PasswordField> div:hover fieldset, .PasswordField fieldset {
    border: 1px solid #f06601 !important;
    border-radius: 10px !important;
    width: 100% !important
  }
  
  .upBtn input {
    margin-bottom: 15px;
  }
  .uploadLabelWrap {
    display: inline-block;
    vertical-align: top;
  }
  .uploadedLabel {
    color: #707070;
    margin-left: 5px;
    font-size: 13px;
    display: inline-block;
  }
  .CloseButton {
    width: 15px !important;
    min-width: 15px !important;
    height: 15px;
    font-weight: 100 !important;
    font-size: 10px;
    padding: 0px !important;
    border-radius: 25px !important;
  }
  .fileUploadLable {
    padding: 8px 14px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12) !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    border: 1px solid #f06601 !important;
    background-color: #f06601 ;
    color: #fff;
  }
  .fileUploadLable:hover {
    background-color: rgb(168, 71, 0) !important;
    cursor: pointer;
  }
  .fileInput {
    display: none;
  }
  .lawDocument {
    font-size: 14px;
    color: #707070;
    /* display: flex; */
    padding-bottom: 5px;
    margin-right: 15px;
  }
  .AlertWrap >div >div >div > svg{
    width: 2em;
    height: 1.5em;
    font-size:1.2rem;
  }
  
  .AlertWrap >div >div >div + div{
    line-height: 1.5 !important;
    font-size: 16px;
    font-weight: 500 !important;
    padding: 0px;
    align-items: center;
    display: inline-flex;
  }
  
  .AlertWrap >div >div >div + div + div > button span svg{
    width: 2em;
    height: 1.5em;
    font-size:1.2rem;
  }
  .AlertWrap > div > div > div {
    padding: 0px;
    align-items: center;
  }
  .borderLessBtn {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    font-weight: bold !important;
    padding: 0px !important;
    color: #F06601 !important;
    padding-left:0px;
    padding-right:0px;
  }
  .PasswordField label{
    transform : translate(14px, 16px) scale(1);
    color: #707070 !important;
    font-size: 14px;
  }
  .PasswordField button {
    margin-right: 0px;
  }
  .borderLess{
    font-family: Roboto,sans-serif;
    color: #F06601 !important;
    padding: 4px 14px !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .uploadLabelName {
    display: inline-block;
  }
  .UploadTooltip {
  position: relative;
  right: 3%;
  }
  
  .PiiDataSwitch, .PiiDataSwitchCheck {
    width: 100% !important;
    padding: 4px !important;
    border: 1px solid #F06601;
    border-radius: 20px;
    z-index: 2 !important;
    height: 39px !important;
  }
  
  .PiiDataSwitch>span, .PiiDataSwitchCheck>span {
    padding: 4px;
  }
  
  .PiiDataSwitch>span {
    transform: translateX(40px) !important;
  }
  
  .PiiDataSwitch>span+span, .PiiDataSwitchCheck>span+span {
    border-radius: 20px;
    background-color: transparent !important;
  }
  
  .PiiDataSwitch>span>span>span, .PiiDataSwitchCheck>span>span>span {
    width: 48px;
    background-color: #F06601;
    height: 28px;
    border-radius: 25px;
  }
  .PiiDataSwitchLabels {
    position: absolute;
    width: 116px;
    left: 0;
    top: 0;
  }
  
  .pgswitch {
    position: relative;
    margin: 7px 0px;
  }
  
  .PiiDataSwitchLabels>span {
    width: 48px;
    justify-content: center;
    font-weight: 500;
    display: inline-flex;
    padding: 12px 0px;
  }
  
  .PiiDataSwitchCheck+span .PiiDataSwitchNo, .PiiDataSwitch+span .PiiDataSwitchYes {
    color: #fff;
  }
  
  .PiiDataSwitchCheck+span .PiiDataSwitchYes, .PiiDataSwitch+span .PiiDataSwitchNo {
    color: #F06601;
  }
  
  .PiiDataSwitchCheck>span>span>span::before {
    content: "External Scan";
    color: #fff !important;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding: 6px 0px;
    justify-content: center;
  }
  
  .PiiDataSwitch>span>span>span::before {
    content: "Advanced Scan";
    color: #fff !important;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding: 5px 0px;
    justify-content: center;
  }
  .backToListButton button {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    outline: 0 !important;
    text-transform: unset !important;
    padding-right: 0px !important;
    margin-bottom: 3px;
  }
  
  .backToListButton button:hover {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    text-transform: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none;
  }
  
  .backToListButton button span img {
    width: 14px !important;
    margin-right: 3px;
  }
  
  .backToListButtonPanel {
    text-align: right;
    padding-bottom: 15px;
  }
  .addButtonStyle {
    margin-left: 20px !important;
    margin: 5px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
    line-height: 1.846 !important;
    border-radius: 50px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    border: 1px solid #f06601 !important;
  }
  .removeButtonStyle {
    margin-left: 20px !important;
    margin: 5px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
    line-height: 1.846 !important;
    border-radius: 50px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    border: 1px solid #f06601 !important;
  }
  .ipInput{
    margin-bottom : 20px !important;
  }
  .DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #f06601;
  padding: 16px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
}
.DialogBoxContext {
  padding: 16px !important;
}
.DialogBoxContext p {
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  margin: 0px;
}
.MainOfficeDialogboxPaper{
  height: 200px !important;
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #f06601 !important;
  width: auto !important;
  max-width: 800px !important;
}
.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #f06601;
  padding: 16px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
}
.DialogBoxContext {
  padding: 16px !important;
}
.DialogBoxContext p {
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  margin: 0px;
}
.CreditMainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.CreditMainOfficeDialogboxPaper .DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CreditMainOfficeDialogboxPaper, .PricingMainOfficeDialogboxPaper{
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #f06601 !important;
  width: auto !important;
  max-width: 800px !important;
}
.CreditMainOfficeDialogboxPaper {
  height: 185px !important;
}
.PricingMainOfficeDialogboxPaper {
  height: 345px !important;
  width: 420px !important;
}
.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(2),
.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(3) {
  text-align: center;
}
.ipInput:focus, .ipInput:hover fieldset, .ipInput input+fieldset {
  border-color: #F06601 !important;
  border-width: 1px !important;
  border-radius: 10px !important;
}
.ipInput > label {
  /* transform: translate(14px, 16px) scale(1); */
  color: #707070 !important;
  /* font-size: 14px; */
}

.ipInput input {
  padding: 5px 7px !important;
  border-radius: 10px !important;
  line-height: 1.846 !important;
  height: 37px !important;
  color: #707070 !important;
  font-size: 14px;
  background-color: #fff;
}

.ipInput {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  max-width: 100%;
  align-items: flex-start;
}

.selectInput {
  line-height: 1.846 !important;
  /* height: 26px !important; */
  color: #707070 !important;
  background-color: #fff;
}

.selectInput input{
  border-radius: 10px !important;
  border-color: #F06601 !important;
  border-width: 1px !important;
}
.selectInput:focus, .selectInput:hover fieldset, .selectInput input+fieldset {
  border-color: #F06601 !important;
  border-width: 1px !important;
  border-radius: 10px !important;
}
.formControl{
  display: flex !important;
  flex-direction: row !important;
  /* justify-content: space-between; */
}
.formControl label{
  color: #F06601 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
}

.inputBaseRoot div{
  border-width: 1px !important;
  border-radius: 10px !important;
  border-color: #F06601 !important;
}
.inputBaseRoot input{
  height: 0.70em !important;
}
.CheckboxformControl span{
  color: #F06601 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
}
.MainOfficeDialogboxPaper{
  height: auto !important;
}
.MainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.MainOfficeDialogboxPaper .DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MainOfficeDialogboxPaper, .PricingMainOfficeDialogboxPaper{
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #f06601 !important;
  width: 50vw !important;
  max-width: 800px !important;
}
.reviewBoxTitle{
  text-align: center !important;
  margin: 0px !important;
  padding: 15px 0px 0px 0px !important;
}
th{
  text-align: left !important;
}
th:first-child{
  width:10% !important;
  padding-left: 4px !important;
}
th:nth-child(2){
  width:15% !important;
}
th:last-child{
  width:15% !important;
} 
th:nth-child(3){
  width:20% !important;
}
th:nth-child(4){
  width:30% !important;
}
td:nth-child(4),td:nth-child(3){
  overflow-wrap: anywhere !important;
}