.Input {
  display: flex;
  padding: 8px;
}

.InputBorder {
  border-radius: 12px !important;
  border-width: 2px !important;
}

.label:focus {
  font-size: 16px;
  font-weight: 500;
  color: #F06601 !important;
}

.HelperText {
  margin-left: 16px !important;
}

/*Change border for textfield*/

.ReactInput:focus, .ReactInput:hover fieldset, .ReactInput input+fieldset {
  border-color: #F06601 !important;
  border-width: 1px !important;
  border-radius: 10px !important;
}

.MuiInputLabel-root:focus{
  color: #000000 !important;
}

.ReactInput > label {
  /* transform: translate(14px, 16px) scale(1); */
  color: #707070 !important;
  /* font-size: 14px; */
}

.ReactInput input {
  padding: 5px 7px !important;
  border-radius: 10px !important;
  line-height: 1.846 !important;
  height: 37px !important;
  color: #707070 !important;
  font-size: 14px;
  background-color: #fff;
}

.ReactInput {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  max-width: 100%;
}