.EditIcon {
  height: 18px;
  width: 18px;
}

.relative {
  padding: 10px;
  position: relative;
  background-color: #fff;
  margin: 10px;
}

.navitem {
  display: inline-block;
  width: 70px;
  height: 30px;
  text-align: center;
  border: gray;
  background-color: #e8562a;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.button {
  /* float: right; */
  background-color: white;
  border: white;
  cursor: pointer;
}

.ButtonCloumn{
  display: inline-block;
}

table > tbody > tr > td > .TooltipWrap {
display: flex;
justify-content: flex-start !important;
}
.TooltipWrap svg {
  margin-left: 5px;
}

.button:disabled svg{
  fill:#a7a3a3 !important;
  cursor: default; 
}

.button:disabled img {
  opacity: 0.7;
  filter: grayscale(100%);
  cursor: default;
}