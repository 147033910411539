.root{
  padding: 0;
}
.ReactStepper {
  padding: 0px !important;
  background-color: transparent !important;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
}
.ReactStep {
  padding: 0px !important;
  width: auto !important;
  margin: 0px 15px 0px 15px;
  display: inline-flex;
  flex-wrap: wrap;
  flex: none !important;
}
.ReactStep:first-child {
  margin-left: 0px !important;
}
.ReactStep button:disabled{
  /* background-color: #f06601 !important; */
  color: #fff;
  font-weight: normal !important;
  opacity: 0.65;
}
.ReactStep svg, .ReactStep span {
  color: #f06601;
}

.ReactStep button:disabled svg, .ReactStep button:disabled span {
  color: #B0B0B0;
}