.backToListButton {
  text-align: right;
}

.saveButton button {
  margin-top: 12px;
  margin-right: 10px;
}

.saveButton {
  padding: 12px 0px;
}

.backToListButton button {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #0051A8 !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 0px !important;
  margin-bottom: 3px;
}

.backToListButton button span img {
  width: 14px !important;
  margin-right: 3px;
}

.saveButton button:last-child {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: 0px !important;
  box-shadow: none !important;
  background-color: transparent;
}