.Main {
  width: 100%; /*246px*/
  padding: 35px;
}

.mainContainer {
  display: flex;
  background-color: #fef9f5;
  box-shadow: inset 0px 5px 10px 1px #ccc;
  min-height: calc(100vh - 101px);
  position: relative;
}

@media (max-width: 599.95px) {
  .mainContainer nav > div {
    display: block !important;
  }
}
