.Dashboard {}

/* .GridBox {
    border-style: solid;
    width: 35%;
    border-radius: 20px;
    border-color: #f06601 ;
} */

.GridBox {
    background-color: #fff !important;
    border: 1px solid #f06601 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
    border-radius: 20px !important;
    padding: 15px !important;
    margin-bottom: 35px !important;
}

.dash_block {
    display: inline-block !important;
    background-color: #fff;
    border: 1px solid #f06601;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
    border-radius: 20px;
    justify-content: center;
    vertical-align: top;
    text-align: center;
    width: 100%;
}

.recentTypo {
    position: relative;
    top: 25px;
}
.tableGrid{
    background-color: #fff !important;
    border: 1px solid #f06601 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
    border-radius: 20px !important;
    padding: 15px !important;
    margin-bottom: 35px !important;
}

.partnerButton {
    display: block !important;
    background-color: #fef9f5;
    border: 1px solid #f06601;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
    border-radius: 20px;
    margin: 12px;
    justify-content: center;
    vertical-align: top;
    text-align: center;
    width: 100%;
}
.partnerButton button{
width: 100%;
}

.partnerinsideButton {
    background: transparent !important;
    box-shadow: none !important;
    color: #f06601  !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
}


.partnerinsideButton:hover {
    background: transparent !important;
    box-shadow: none !important;
    color: #f06601  !important;
    text-transform: capitalize !important;
}

.dash_head {
	font-size: 20px !important;
	margin-top: 40px !important;
    margin-bottom: 15px !important;
    color: #f06601  !important;
}

.dash_count {
	font-size: 30px !important;
	margin-top: 20px !important;
    margin-bottom: 30px !important;
    font-weight: bold;
    cursor: pointer;
}
.EditIcon {
    width:18px !important;
    height:18px !important;
}