
.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
    border: 1px solid #f06601;
    position: relative;
}

.FilterWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    align-items: flex-end;
    padding: 0px 0px 5px 0px;
    width: auto;
  }
  
  .FilterInput {
    margin-bottom: 15px;
    text-align: right;
  }
  
  .FilterSearchButton {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .FilterResetButton {
    margin-bottom: 10px;
  }
  
  .FilterAddWrap {
    float: right;
    margin-top: 0px;
    text-align: right !important;
  }

  .searchbarButton {
    display: inline-block;
    margin-top: 0px;
    vertical-align: middle;
    padding: 8px 0px 8px 25px;
  }

  .borderLess {
    font-family: Roboto, sans-serif;
    color: #f06601 !important;
    padding: 4px 18px !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .BackToButton {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    font-size: 15px !important;
    font-weight: normal !important;
    color: #f06601 !important;
    outline: 0 !important;
    text-transform: unset !important;
    padding-right: 10px !important;
    margin-bottom: 3px;
  }
  
  .BackToButton img {
    width: 15px !important;
    margin-right: 3px;
  }

  .FilterResetButton button {
    background-color: transparent !important;
    border: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    box-shadow: none !important;
  }
  
  .FilterResetButton button span {
    font-weight: 600 !important;
  }

  .FilterWrap1 {
    margin: 6px 5px 5px 15px;
    padding: 1px;
  }
  
  .FilterWrap2 {
    margin: 6px 5px 5px 5px;
    padding: 1px;
  }

  .FilterDateInput > div {
    border-radius: 10px !important;
    border-width: 1px !important;
    border-color: #F06601 !important;
    height: 50px !important;
  }
  .selectInput > div{
    line-height: 1.846 !important;
    height: 50px !important;
    background-color: #fff;
  }

  .selectInput:focus, .selectInput:hover fieldset, .selectInput input+fieldset {
    border-color: #F06601 !important;
    border-width: 1px !important;
    border-radius: 10px !important;
  }
  
.ScrollTable>div {
    max-height: calc(100vh - 280px) !important;
    overflow-x: auto !important;
}
.ScrollTable svg{
    fill:#e53935 !important;
}