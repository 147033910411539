.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
    border: 1px solid #f06601;
    position: relative;
  }
  .raswitch {
    position: relative;
    margin: 7px 0px;
  }
  .PublishButton {
    color: #ffffff !important;
    border-radius: 20px !important;
    padding: 4px 14px !important;
    font-size: 14px !important;
  }
  
  .PublishButton:hover {
    color: #fff !important;
    background-color: rgb(0, 56, 117) !important;
  }
  .uploadButton {
    cursor: pointer;
  }
  .MainOfficeDialogboxPaper{
    height: 200px !important;
    border-radius: 24px !important;
    margin-top: 66px !important;
    border-bottom: 1px solid #f06601 !important;
    width: auto !important;
    max-width: 800px !important;
  }
  .MainOfficeScrollPaper {
    align-items: center !important;
  }
  .EditIcon {
    height: 18px !important;
    width: 18px !important;
  }
  .DialogBoxTitle {
    text-align: center;
    border-bottom: 1px solid #f06601;
    padding: 16px 24px;
  }
  .DialogBoxTitle h1 {
    margin-bottom: 0px !important;
  }
  .DialogBoxContext {
    padding: 16px !important;
  }
  .DialogBoxContext p {
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #707070;
    margin: 0px;
  }

  .backToListButton button {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    text-transform: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none;
  }
  
  .backToListButton button:hover {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #f06601  !important;
    text-transform: unset !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none;
  }
  
  .backToListButton button span img {
    width: 14px !important;
    margin-right: 3px;
  }
  
  .backToListButtonPanel {
    text-align: right;
  }