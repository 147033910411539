.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
    border-radius: 20px !important;
    border: 1px solid #f06601;
    position: relative;
  }

  .FilterWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    align-items: flex-end;
  }

  .FilterInput {
    width: 240px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .FilterSearchButton {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .FilterResetButton {
    margin-bottom: 10px;
    color: #f06601;
  }

  .FilterAddWrap {
    margin-bottom: 15px;
    text-align: right;
  }

  .FilterResetButton button {
    /* background-color: transparent !important; */
    border: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    box-shadow: none !important;
  }

  .FilterResetButton button span {
    font-weight: 600 !important;
  }

  .TableWrap table button:hover {
    background-color: transparent !important;
  }

  .TableWrap h6 {
    color: #707070;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.6;
  }

  .TableWrap thead tr th {
    background-color: #fef9f5 !important;
    vertical-align: middle !important;
    line-height: 1.5 !important;
    color: #f06601 ;
    font-size: 12px;
    font-weight: 700 !important;
    padding: 8px !important;
  }

  .TableWrap thead tr th>span>div {
    color: #f06601  !important;
  }

  .TableWrap tbody tr td {
    color: #707070 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 8px !important;
    line-height: 1.846 !important;
  }

  TableWrap tbody tr td svg, .TableWrap tbody tr td button {
    color: #0072ed !important;
  }

  .FilterDropInput {
    width: 320px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .FilterDateInput{
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .multiSelectDropdownInput input {
    padding: 5px 7px !important;
    border-radius: 10px !important;
    line-height: 1.846 !important;
    height: 37px !important;
    color: #707070 !important;
    font-size: 14px;
    background-color: #fff;
  }

  .multiSelectDropdownInput label {
    transform: translate(14px, 16px) scale(1);
    color: #707070 !important;
    font-size: 14px;
  }

  .EditIcon {
    height: 18px;
    width: 18px;
  }

  .Editor {
    border: 1px solid #f06601;
    border-radius: 12px !important;
    height: 300px !important;
    color: #707070 !important;
    font-size: 14px;
    background-color: #fff;
  }

  .outlineDate:focus, .outlineDate:hover fieldset, .outlineDate input+fieldset {
    border-color: #f06601  !important;
    border-width: 1px !important;
    border-radius: 10px !important;
  }

  .outlineDate > label {
    transform: translate(14px, 16px) scale(1);
    color: #707070 !important;
    font-size: 14px;
  }

  .outlineDate input {
    padding: 5px 7px !important;
    border-radius: 10px !important;
    line-height: 1.846 !important;
    height: 37px !important;
    color: #707070 !important;
    font-size: 14px;
    background-color: #fff;
  }

  .outlineDate {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .outlineDate > label + div > input {
    outline: 0px !important;
  }

 /* th:nth-child(6) {
  width: 16% !important;
} */

th:nth-child(7) {
  text-align: left !important;
}

tfoot td > div {
  flex-wrap: wrap;
  justify-content: flex-end;
}
tfoot  {
  display: table-footer-group !important;
}

/*report status*/

 .report_status_table table > tbody > tr > td:nth-child(1) {
  width: 13% !important;
}

.report_status_table table > tbody > tr > td:nth-child(2) {
  width: 12% !important;
}

.report_status_table table > tbody > tr > td:nth-child(3) {
  width: 5% !important;
}

.report_status_table table > tbody > tr > td:nth-child(4) {
  width: 14% !important;
}

.report_status_table table > tbody > tr > td:nth-child(5) {
  width: 20% !important;
}

.report_status_table table > tbody > tr > td:nth-child(6) {
  width: 12% !important;
}

.report_status_table table > tbody > tr > td:nth-child(7) {
  width: 12% !important;
}

.report_status_table table > tbody > tr > td:nth-child(8) {
  width: 12% !important;
}

.report_status_table table > thead > tr > th:nth-child(8) {
  text-align: left !important;
}