.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #f06601;
  position: relative;
}
.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}
.FilterInput {
  width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}
.FilterResetButton {
  margin-bottom: 10px;
}
.FilterAddWrap {
  float: right;
  margin-top: 20px;
}
.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}
.FilterResetButton button span {
  font-weight: 600 !important;
}
.ActionButtons button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.PasswordField input {
  padding: 5px 7px !important;
  border-radius: 10px !important;
  line-height: 1.846 !important;
  height: 37px !important;
  color: #707070 !important;
  font-size: 14px;
  background-color: #fff;
}

.PasswordField {
  color: #707070 !important;
  font-size: 14px;
}
.PasswordField > div {
  width: 100%;
}

.PasswordField > div:focus,
.PasswordField > div:hover fieldset,
.PasswordField fieldset {
  border: 1px solid #f06601 !important;
  border-radius: 10px !important;
  width: 100% !important;
}

.AlertWrap >div >div >div > svg{
  width: 2em;
  height: 1.5em;
  font-size:1.2rem;
}

.AlertWrap >div >div >div + div{
  line-height: 1.5 !important;
  font-size: 16px;
  font-weight: 500 !important;
  padding: 0px;
  align-items: center;
  display: inline-flex;
}

.AlertWrap >div >div >div + div + div > button span svg{
  width: 2em;
  height: 1.5em;
  font-size:1.2rem;
}
.AlertWrap > div > div > div {
  padding: 0px;
  align-items: center;
}
/* .PasswordField label{
  transform : translate(14px, 16px) scale(1);
  color: #707070 !important;
  font-size: 14px;
} */
.borderLess{
    font-family: Roboto,sans-serif;
    color: #F06601 !important;
    padding: 4px 14px !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.PassField {
  margin-right: 0px !important;
}
.MuiFormControlLabel-root{
  margin-right: 5px !important;
}
.containerSpacing{
  margin-top: 10px !important;
  display: flex;
}
.EditIcon {
  height: 38px !important;
  width: 38px !important;
}
.removeItemSpacing{
  padding-left: 0px !important;
}   