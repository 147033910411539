.borderLessBtn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: bold !important;
  padding: 0px !important;
  color: #f06601 !important;
  padding-left: 0px;
  padding-right: 0px;
}

.borderLess {
  font-family: Roboto, sans-serif;
  color: #f06601 !important;
  padding: 4px 14px !important;
  line-height: 1.846 !important;
  border-radius: 20px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.disfield input {
  background-color: #e1e1e1;
}
.addButtonStyle {
  margin-left: 20px !important;
  margin: 5px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
  line-height: 1.846 !important;
  border-radius: 50px !important;
  font-weight: 500!important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  border: 1px solid #f06601 !important;
}
.removeButtonStyle {
  margin-left: 20px !important;
  margin: 5px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
  line-height: 1.846 !important;
  border-radius: 50px !important;
  font-weight: 500!important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  border: 1px solid #f06601 !important;
}
.ipInput{
  margin-bottom : 20px !important;
}
.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #f06601;
  padding: 16px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
}
.DialogBoxContext {
  padding: 16px !important;
}
.DialogBoxContext p {
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  margin: 0px;
}
.CreditMainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.CreditMainOfficeDialogboxPaper .DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CreditMainOfficeDialogboxPaper, .PricingMainOfficeDialogboxPaper{
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #f06601 !important;
  width: auto !important;
  max-width: 800px !important;
}
.CreditMainOfficeDialogboxPaper {
  height: 185px !important;
}
.PricingMainOfficeDialogboxPaper {
  height: 345px !important;
  width: 420px !important;
}
.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(2),
.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(3) {
  text-align: center;
}
.ipInput:focus, .ipInput:hover fieldset, .ipInput input+fieldset {
  border-color: #F06601 !important;
  border-width: 1px !important;
  border-radius: 10px !important;
}
.ipInput > label {
  /* transform: translate(14px, 16px) scale(1); */
  color: #707070 !important;
  /* font-size: 14px; */
}

.ipInput input {
  padding: 5px 7px !important;
  border-radius: 10px !important;
  line-height: 1.846 !important;
  height: 37px !important;
  color: #707070 !important;
  font-size: 14px;
  background-color: #fff;
}

.ipInput {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  max-width: 100%;
  align-items: flex-start;
}

.selectInput {
  line-height: 1.846 !important;
  /* height: 26px !important; */
  color: #707070 !important;
  background-color: #fff;
}

.selectInput input{
  border-radius: 10px !important;
  border-color: #F06601 !important;
  border-width: 1px !important;
}
.selectInput:focus, .selectInput:hover fieldset, .selectInput input+fieldset {
  border-color: #F06601 !important;
  border-width: 1px !important;
  border-radius: 10px !important;
}
.formControl{
  display: flex !important;
  flex-direction: row !important;
  /* justify-content: space-between; */
}
.formControl label{
  color: #F06601 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
}

.inputBaseRoot div{
  border-width: 1px !important;
  border-radius: 10px !important;
  border-color: #F06601 !important;
}
.inputBaseRoot input{
  height: 0.70em !important;
}
.CheckboxformControl span{
  color: #F06601 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
}
.MainOfficeDialogboxPaper{
  height: auto !important;
}
.MainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.MainOfficeDialogboxPaper .DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MainOfficeDialogboxPaper, .PricingMainOfficeDialogboxPaper{
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #f06601 !important;
  width: 50vw !important;
  max-width: 800px !important;
}
.reviewBoxTitle{
  text-align: center !important;
  margin: 0px !important;
  padding: 15px 0px 0px 0px !important;
}
th{
  text-align: left !important;
}
th:first-child{
  width:10% !important;
  padding-left: 4px !important;
}
th:nth-child(2){
  width:15% !important;
}
th:last-child{
  width:15% !important;
} 
th:nth-child(3){
  width:20% !important;
}
th:nth-child(4){
  width:30% !important;
}
td:nth-child(4),td:nth-child(3){
  overflow-wrap: anywhere !important;
}
