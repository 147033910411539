.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #f06601;
  position: relative;
}

.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
}

/* label{
  display: inline-block;
  background-color: #f06601;
  font-size: small;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
} */

.FilterInput {
  width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterResetButton {
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right !important;
  margin-top: 20px;
}

.FilterAddButton {
  text-align: right !important;
  margin-bottom: 15px;
}

.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}

.FilterResetButton button span {
  font-weight: 600 !important;
}

th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

th {
  padding: 8px !important;
  border-bottom: none !important;
  line-height: 1.5 !important;
  font-size: 12px !important;
  color: #F06601 !important;
  font-weight: 600 !important;
  background: #fef9f5 !important;
}

th span, th div {
  font-size: 12px !important;
  color: #f06601 !important;
  font-weight: 600 !important;
}

table>thead {
  background-color: transparent !important;
}

.ScrollTable>div {
  max-height: calc(100vh - 351px) !important;
  overflow-x: auto !important;

}
.ScrollTable svg{
  fill:#e53935 !important;
}

td button {
  padding: 0px 10px !important;
  color: #0072ed !important;
}

td {
  padding: 8px !important;
  line-height: 1.846 !important;
  color: #707070 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

tfoot {
  border-top: 1px solid #eee;
  display: table-footer-group !important;
}

tfoot tr td > div {
  justify-content: flex-end !important;
}

.EditIcon {
  height: 18px !important;
  width: 18px !important;
}

/*Switch*/

.PublishSwitch, .PublishSwitchCheck {
  width: 100px !important;
  padding: 4px !important;
  border: 1px solid #f06601;
  border-radius: 20px;
  z-index: 2 !important;
  height: 37px !important;
}

.PublishSwitch>span, .PublishSwitchCheck>span {
  padding: 3px 6px;
}

.PublishSwitch>span {
  transform: translateX(40px) !important;
}

.PublishSwitch>span+span, .PublishSwitchCheck>span+span {
  border-radius: 20px;
  background-color: transparent !important;
}

.PublishSwitch>span>span>span, .PublishSwitchCheck>span>span>span {
  width: 48px;
  background-color: #f06601;
  height: 28px;
  border-radius: 25px;
}

.PublishSwitchLabels {
  position: absolute;
  width: 116px;
  left: 0;
  top: 0;
}

.raswitch {
  position: relative;
  margin: 7px 0px;
}

.PublishSwitchLabels>span {
  width: 48px;
  justify-content: center;
  font-weight: 500;
  display: inline-flex;
  padding: 16px 0px;
  font-size: 14px;
}

.PublishSwitchCheck+span .PublishSwitchNo, .PublishSwitch+span .PublishSwitchYes {
  color: #fff;
  font-size: 5px;
}

.PublishSwitchCheck+span .PublishSwitchYes, .PublishSwitch+span .PublishSwitchNo {
  color: #f06601 ;
  font-size: 5px;
}

.PublishSwitchCheck>span>span>span::before {
  content: "No";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
}

.PublishSwitch>span>span>span::before {
  content: "YES";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
}
.backToListButton button {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #f06601  !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 0px !important;
  margin-bottom: 3px;
}

.backToListButton button:hover {
  font-size: 12px !important;
  font-weight: normal !important;
  color: #f06601  !important;
  text-transform: unset !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none;
}

.backToListButton button span img {
  width: 14px !important;
  margin-right: 3px;
}

.backToListButtonPanel {
  text-align: right;
  padding-bottom: 15px;
}

.uploadButton {
  cursor: pointer;
}
.backToListButton button.ActionButton, .backToListButton button.ActionButton:hover, .backToListButton button.ActionButton:focus, .backToListButton button.ActionButton:active{
  padding: 4px 14px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
  line-height: 1.846 !important;
  border-radius: 20px !important;
  font-weight: 500!important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  border: 1px solid #f06601 !important;
  background-color: #f06601  !important;
  color: #fff !important;
  margin-left: 10px;
}

tfoot td > div {
  flex-wrap: wrap;
  justify-content: flex-end;
}
.PublishButton {
  color: #ffffff !important;
  border-radius: 20px !important;
  padding: 4px 14px !important;
  font-size: 14px !important;
}

.PublishButton:hover {
  color: #fff !important;
  background-color: rgb(0, 56, 117) !important;
}

.AlertWrap >div >div >div > svg{
  width: 2em;
  height: 1.5em;
  font-size:1.2rem;
}

.AlertWrap >div >div >div + div{
  line-height: 1.5 !important;
  font-size: 16px;
  font-weight: 500 !important;
  padding: 0px;
  align-items: center;
  display: inline-flex;
}

.AlertWrap >div >div >div + div + div > button span svg{
  width: 2em;
  height: 1.5em;
  font-size:1.2rem;
}
.AlertWrap > div > div > div {
  padding: 0px;
  align-items: center;
}
tfoot button{
  color: #e53935 !important;
}
tfoot svg {
  fill:#e53935 !important;
}
.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #f06601;
  padding: 16px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
}
.DialogBoxContext {
  padding: 16px !important;
}
.DialogBoxContext p {
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  margin: 0px;
}
.MainOfficeDialogboxPaper{
  height: 200px !important;
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #f06601 !important;
  width: auto !important;
  max-width: 800px !important;
}
.MainOfficeScrollPaper {
  align-items: center !important;
}
.EditIcon {
  height: 18px !important;
  width: 18px !important;
}
.MenuButton {
  margin-top: 46px !important;
  margin-right: 30px !important;
}
.MuiMenuPaper{
  top:135px !important;
  left:1105px !important;
}

.MenuButton ul{

  border: 1px solid  #f06601;
  /* border-radius: 0px; */
  box-shadow: none !important;
}
.para {
  position: relative;
  left: 58%;
}
.CircleIcon2 {
  color: #f06601 ;
  position: relative;
  width: 0.7em !important;
}
/* 
th:nth-child(1),
th:nth-child(7),
th:nth-child(6){
  width: 15% !important;

}
th:nth-child(4){
  width: 12% !important;
}
th:nth-child(2) {
  width: 20% !important;

}
th:nth-child(5){
  width: 15% !important;
}

th:nth-child(3) {
  width: 9% !important;

} */
.tooltiptext {
  font-size: 11px!important;
}
td:last-child > div{
  display: flex;
  padding-right: 6em;
}
table{
  border-collapse: collapse;
}

.assessmentTableWrap td:nth-child(2),.assessmentTableWrap td:nth-child(3), .assessmentTableWrap td:nth-child(5), .assessmentTableWrap td:nth-child(6), .assessmentTableWrap td:nth-child(4) {
  width: 15% !important;
}
.assessmentTableWrap td:nth-child(2){
  width: 10% !important;
}

.assessmentTableWrap td:nth-child(1){
  width: 18% !important;
  word-wrap: break-word !important;
}
.assessmentTableWrap th:nth-child(2),.assessmentTableWrap th:nth-child(3), .assessmentTableWrap th:nth-child(5), .assessmentTableWrap th:nth-child(6), .assessmentTableWrap th:nth-child(4) {
  width: 15% !important;
}
.assessmentTableWrap th:nth-child(2){
  width: 10% !important;
}

.assessmentTableWrap th:nth-child(1){
  width: 18% !important;
  word-wrap: break-word !important;
}