.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
    border: 1px solid #f06601;
    position: relative;
  }

  tfoot td > div {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  tfoot  {
    display: table-footer-group !important;
  }