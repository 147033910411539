.paper {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  -webkit-box-shadow: none !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
  border-radius: 20px !important;
  border: 1px solid #f06601;
  position: relative;
}

.borderLess {
  font-family: Roboto, sans-serif;
  color: #f06601 !important;
  padding: 4px 14px !important;
  line-height: 1.846 !important;
  border-radius: 20px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.searchbarInput {
	width: 25%;
	display: inline-block;
	vertical-align: top;
	padding-bottom: 25px;
}

.searchbarButton {
	display: inline-block;
  margin-top: 0px;
	vertical-align: middle;
  padding: 8px 0px 0px 25px;
}

.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}

.ActionButtons button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterInput {
  margin-bottom: 15px;
  text-align: right;
}

.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterResetButton {
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
}

.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}

.FilterResetButton button span {
  font-weight: 600 !important;
}

th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

th {
  padding: 8px !important;
  border-bottom: none !important;
  line-height: 1.5 !important;
  font-size: 12px !important;
  color: #f06601 !important;
  font-weight: 600 !important;
	background: #fef9f5 !important;
  text-align: left;
}

table>thead {
  background-color: transparent !important;
}
.ScrollTable table tr th:first-child {
  width:20% !important ;
}
.ScrollTable table tr th:nth-child(3) {
  width: 15% !important;
}

.ScrollTable>div {
  max-height: calc(100vh - 351px) !important;
  overflow-x: auto !important;
}
.ScrollTable svg{
  fill:#e53935 !important;
}
td button {
  padding: 0px 10px !important;
  color: #0072ed !important;
  justify-content: right !important;
}

td {
  padding: 8px !important;
  line-height: 1.846 !important;
  color: #707070 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
td > div {
  justify-content: right !important;
}

tfoot {
  border-top: 1px solid #eee;
  display: table-footer-group !important;
}

tfoot tr td > div {
  justify-content: flex-end !important;
}

tfoot tr td > div {
  justify-content: flex-end !important;
}

td button:hover {
  background-color: transparent !important;
}

.EditIcon {
  height: 18px !important;
  width: 18px !important;
}

.backToListButton button {
  font-size: 12px !important;
  font-weight: normal !important;
  color: #f06601  !important;
  text-transform: unset !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none;
}

.backToListButton button:hover {
  font-size: 12px !important;
  font-weight: normal !important;
  color: #f06601  !important;
  text-transform: unset !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none;
}

.backToListButton button span img {
  width: 14px !important;
  margin-right: 3px;
}

.backToListButtonPanel {
  text-align: right;
}

tfoot td > div {
  flex-wrap: wrap;
  justify-content: flex-end;
}
.OBLogoImg {
  display: block;
  max-width: 83px;
  max-height: 83px;
  margin-bottom: 7px;
}
.OBLogoImg img{
  width: 100%;
}
tfoot button{
  color: #e53935 !important;
}
tfoot svg {
  fill:#e53935 !important;
}
/* .Component-horizontalScrollContainer-5 div {
  justify-content: right !important;
} */
table > tbody > tr > td > div {
  justify-content: flex-end !important;
}
.raswitch {
  position: relative;
  margin: 7px 0px;
}
.PublishButton {
  color: #ffffff !important;
  border-radius: 20px !important;
  padding: 4px 14px !important;
  font-size: 14px !important;
}

.PublishButton:hover {
  color: #fff !important;
  background-color: rgb(0, 56, 117) !important;
}
.pen {
  font-size: 16px;
  font-weight: bolder;
}
.ScrollTable table {
  border-collapse: collapse;
}
.ScrollTable table td:last-child > div{
  display: flex;
}
table {
  width: 100%;
}

.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #f06601 !important;
  padding: 5px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}
.MainOfficeDialogboxPaper {
  height: auto !important;
  border: 1px solid #f06601 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
  max-height: 80vh !important;
  max-width: 60vw !important;
}
.MainOfficeSampleReportDialogboxPaper {
  height: auto !important;
  border: 1px solid #f06601 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
  width:35rem;
  max-height: 80vh !important;
  /* max-width: 60vw !important; */
}
.aboutButton{
  margin-right: 15px !important;
  border-radius: 50px !important;
  padding: 4.5px 14px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}
.MuiGridItem {
  max-width:140px !important;
  padding-left: 86px !important;
  padding-top: 30px !important;
  align-self: center !important;
}
.ReactInput1{
  margin-top: 15px !important;
}
.ReactInput2{
  margin-bottom: 15px !important;
}
.title{
  margin-bottom: 15px !important;
  padding-bottom: 0px !important;
  margin-left: 7px !important;
  margin-top: 15px !important;
}
.GuestCientButton button{
  float: right !important;
  margin: -62px auto !important;
}