.paper {
    margin-bottom: 20px;
    margin-top: 15px;
    margin-right: 12px;
    padding: 15px;
    background-color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
    border-radius: 20px !important;
    border: 1px solid #f06601;
    position: relative;
}

.ActionButtons button {
    margin-right: 10px;
    margin-bottom: 10px;
}

.borderLess{
    font-family: Roboto,sans-serif;
    color: #F06601 !important;
    padding: 4px 14px !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500!important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.GuestCientButton button{
  float: right !important;
  margin: -62px auto !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 17px !important;
  font-weight: bolder !important;
  color: #e1e1e1  !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 14px !important;
}

.MuiFormControlLabel-root{
  margin-right: 5px !important;
}

.crossBtn button{
  min-width: 0px !important;
}

.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #F06601 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}
