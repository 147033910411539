.CenterItems {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  flex-direction: column;
  transform: translate(calc(50% - 50px), calc(50% - 50px));
}

.LoadingPadding {
  color: #333;
}

.backdroptext {
  color: #333;
  font-size: 24px;
}
