.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #FFF;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  border-radius: 20px !important;
  border: 1px solid #f06601;
  position: relative;
}

.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
  padding: 0px 0px 5px 0px;
  width: auto;
}
.FilterWrap1 {
  margin: 6px 5px 0px 15px;
  padding: 1px;
}
.FilterWrap2 {
  margin: 6px 5px 0px 5px;
  padding: 1px;
}

.FilterInputadduser {
  /* width: 200px; */
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.FilterInputgotolist {
  text-align: right;
}

.FilterInputgotolist button {
  margin-left: 10px;
}

.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterResetButton {
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right;
  /* margin-top: 20px; */
  margin-top: 0px;
  text-align: right !important;
}

.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}

.FilterResetButton button span {
  font-weight: 600 !important;
}

.InputField {
  border-color: #f06601  !important;
  border-radius: 12px !important;
  border-width: 1px !important;
  width: 320px;
  height: auto;
  font-size: 14px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ButtonGroup1 {
  display: inline-block !important;
}

.ButtonGroup2 {
  display: inline-block !important;
  margin-bottom: 10px;
}

.ButtonGroup1 a, .ButtonGroup2 a {
  text-decoration: none;
}

.backToListButton {
  text-align: right;
  margin-bottom: 15px !important;
}

th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.paper table th {
  padding: 8px !important;
  border-bottom: none !important;
  line-height: 1.5 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #002f60;
  width: 12% !important;
}

th:nth-child(6) {
  width:5px !important;
}

.paper table td {
  padding: 8px !important;
  line-height: 1.846 !important;
  color: #707070 !important;
  font-size: 12px !important;
  font-weight: normal !important;
  width: 12% !important;
  overflow-wrap: anywhere !important;
}

tr {
  height: unset !important;
}

table tr td {
  border-bottom: 1px solid #eee !important
}

table>thead {
  background-color: transparent !important;
}

.FormHelperText {
  color: #e53935 !important;
}

.AdminScrollTable>div {
  max-height: calc(100vh - 345px) !important;
  overflow-x: auto !important;
}
.AdminScrollTable svg{
  fill:#e53935 !important;
}
.BackToButton {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #f06601  !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 0px !important;
  margin-bottom: 3px;
}

.BackToButton img {
  width: 14px !important;
  margin-right: 3px;
}

.ButtonGroup2 button svg{
  margin-right: 5px;
}

.EditIcon{
  width: 18px !important;
  height:18px !important;
}

tfoot  {
  display: table-footer-group !important;
}
tfoot tr td > div{
  justify-content: flex-end;
}
tfoot button{
  color: #e53935 !important;
}
tfoot svg {
  fill:#e53935 !important;
}
.MainOfficeDialogboxPaper {
  height: 215px !important;
  border: 1px solid #f06601 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}
.AdminScrollTable td div{
  width: 100px !important;
} 