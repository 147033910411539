.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #f06601;
  position: relative;
}
.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}
.FilterInput {
  width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}
.FilterResetButton {
  margin-bottom: 10px;
}
.FilterAddWrap {
  float: right;
  margin-top: 20px;
}
.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}
.FilterResetButton button span {
  font-weight: 600 !important;
}

.backToListButton button {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #f06601  !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 0px !important;
  margin-bottom: 3px;
}

.backToListButton button span img {
  width: 14px !important;
  margin-right: 3px;
}

.backToListButton {
  text-align: right;
  margin-right: 15px;
  width: 100%;
}
th:nth-child(5) {
  text-align: left !important;
}

tfoot  {
  display: table-footer-group !important;
}
tfoot tr td > div{
  justify-content: flex-end;
  flex-wrap: wrap;
}
tfoot button{
  color: #e53935 !important;
}
tfoot svg {
  fill:#e53935 !important;
}